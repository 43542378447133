// import authenticationReducer from "./authentication"

import { logger } from "./middlewares"

interface State {
  // auth: any
}

export const initialState: State = {
  // auth: authenticationReducer.initialState,
}

export default function mainReducer(state: State, action: object) {
  // Receiving previous state here
  // const { auth } = state

  // Receiving current state here
  const currentState = {
    // auth: authenticationReducer.reducer(auth, action),
  }

  // Middlewares
  logger(action, state, currentState)

  return currentState
}
