exports.components = {
  "component---src-ui-pages-404-tsx": () => import("./../../../src/ui/pages/404.tsx" /* webpackChunkName: "component---src-ui-pages-404-tsx" */),
  "component---src-ui-pages-hakkimizda-tsx": () => import("./../../../src/ui/pages/hakkimizda.tsx" /* webpackChunkName: "component---src-ui-pages-hakkimizda-tsx" */),
  "component---src-ui-pages-iletisim-tsx": () => import("./../../../src/ui/pages/iletisim.tsx" /* webpackChunkName: "component---src-ui-pages-iletisim-tsx" */),
  "component---src-ui-pages-index-tsx": () => import("./../../../src/ui/pages/index.tsx" /* webpackChunkName: "component---src-ui-pages-index-tsx" */),
  "component---src-ui-templates-article-list-tsx": () => import("./../../../src/ui/templates/article/list.tsx" /* webpackChunkName: "component---src-ui-templates-article-list-tsx" */),
  "component---src-ui-templates-article-page-tsx": () => import("./../../../src/ui/templates/article/page.tsx" /* webpackChunkName: "component---src-ui-templates-article-page-tsx" */),
  "component---src-ui-templates-interview-list-tsx": () => import("./../../../src/ui/templates/interview/list.tsx" /* webpackChunkName: "component---src-ui-templates-interview-list-tsx" */),
  "component---src-ui-templates-interview-page-tsx": () => import("./../../../src/ui/templates/interview/page.tsx" /* webpackChunkName: "component---src-ui-templates-interview-page-tsx" */)
}

